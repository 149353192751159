import React, {useState} from 'react';
import axios from 'axios';

const endpoint = 'https://api.wm.nordiceasy.ovh/api/token';

const Form = () => {
    const [company, setCompany] = useState('');
    const [property, setProperty] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [token, setToken] = useState('');
    const [url, setUrl] = useState(null);

    const getUrl = async () => {
        const response = await fetch(endpoint);
        const data = await response.json();

        return data;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setUrl(null);

        if (!company || !property) {
            setLoading(false);
            setError('Missing company or property');
            return;
        }

        const options = {
            headers: {'token': token}
        }

        axios.post(endpoint, {company: parseInt(company)}, options)
            .then(response => {
                const token = response.data.token;
                setUrl(`https://api.webmegler.no/legacy/xml/wnsokemotorxml.exe?/k${company}/aeneiendom/o${property}&access_token=${token}`);
            })
            .catch(error => {
                setError('Error in fetching data')
            })

        setLoading(false);
    }

    return (
        <section className="section">
            <div className="container">
                <div className="columns is-8">
                    <div className="column">
                        <form onSubmit={handleSubmit}>
                            {error && <p className="help is-danger">{error}</p>}

                            <div className="field">
                                <label className="label">Token</label>
                                <div className="control">
                                    <input className="input" type="password" name="token" value={token} onChange={(e) => {
                                        setToken(e.target.value)
                                        setError(false)
                                    }} placeholder="Token"/>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Company</label>
                                <div className="control">
                                    <input className="input" type="number" name="company" value={company} onChange={(e) => {
                                        setCompany(e.target.value)
                                        setError(false)
                                    }} placeholder="Konsern ID"/>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Property</label>
                                <div className="control">
                                    <input className="input" type="number" name="company" value={property} onChange={(e) => {
                                        setProperty(e.target.value)
                                        setError(false)
                                    }} placeholder="Oppdragsnummer"/>
                                </div>
                            </div>

                            <div className="control">
                                <button className={`button is-primary ${loading ? 'is-loading' : ''}`}>Get</button>
                            </div>

                        </form>
                    </div>

                    <div className="column">
                        {url &&
                            <>
                        <h2>Url to WM:</h2>
                        <p>
                            <a className="is-link" target="_blank" href={url}>Click to open in new tab</a>
                        </p>
                        </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Form;
