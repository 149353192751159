import React from 'react';
import Hero from "./Hero";
import Form from "./Form";

function App() {
    return (
        <>
            <Hero/>
            <Form/>
        </>
    );
}

export default App;
